import React from 'react';
import './Header.css';

function Header({ header }) {
    return (
        <div className="header">
            <h2>{header}</h2>
            <span>{header}</span>
        </div>
    )
}

export default Header
