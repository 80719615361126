import { createSlice } from '@reduxjs/toolkit';

export const imageSlice = createSlice({
    name: 'images',
    initialState: {
        images: null,
        profile_pic: null,
    },
    reducers: {
        setImages: (state, action) => {
            state.images = action.payload;
        },
        setProfileImage: (state, action) => {
            state.profile_pic = action.payload;
        }
    }
});

export const { setImages, setProfileImage } = imageSlice.actions;

export const selectImages = (state) => state.images?.images?.images;
export const selectProfileImage = (state) => state.images?.profile_pic?.profile_pic;

export default imageSlice.reducer;