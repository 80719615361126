import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import pageReducer from './slice/pageSlice';
import baseReducer from './slice/baseSlice';
import imageReducer from './slice/imageSlice';


export default configureStore({
    reducer: {
        pages: pageReducer,
        images: imageReducer,
        documents: null,
        profilepicture: null,
        captcha: false,
        base: baseReducer,
        //baseURL: "https://dylan.primic.com"
        baseURL: "http://portfolio.localserver"
    },
    middleware: {thunk, getDefaultMiddleware}
});