import { Paper } from "@material-ui/core";
import React from "react";
import "./PostDate.css";

function PostDate({ date }) {
  const day = () => {
    let new_date = date.slice(8, 10);
    return new_date;
  };
  const month = () => {
    let new_date = date.slice(5, 7);
    console.log('New Date:', new_date);
    if (new_date == 1) {
        new_date = 'Jan';
    }
    else if (new_date == 2) {
        new_date = 'Feb';
    }
    else if (new_date == 3) {
        new_date = 'Mar';
    }
    else if (new_date == 4) {
        new_date = 'Apr';
    }
    else if (new_date == 5) {
        new_date = 'May';
    }
    else if (new_date == 6) {
        new_date = 'Jun';
    }
    else if (new_date == 7) {
        new_date = 'Jul';
    }
    else if (new_date == 8) {
        new_date = 'Aug';
    }
    else if (new_date == 9) {
        new_date = 'Sep';
    }
    else if (new_date == 10) {
        new_date = 'Oct';
    }
    else if (new_date == 11) {
        new_date = 'Nov';
    }
    else if (new_date == 12) {
        new_date = 'Dec';
    }
    return new_date;
  };
  const year = () => {
    let new_date = date.slice(0, 4);
    return new_date;
  };

  return (
    <div className="postdate">
        <Paper className="postdate__paper" elevetion={3}>
            <h5>{day()}</h5>
            <span>{month()}</span>
        </Paper>
    </div>
  );
}

export default PostDate;
