import React, { useEffect } from "react";
import "./Resume.css";
import axios from "axios";
import Header from "../Header";
import Skill from "./Skill";
import TimelineWrapper from "./TimelineWrapper";
import { Container, Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { setPage, selectPage, selectPages } from "../../store/slice/pageSlice";
import { selectBaseURL, selectProductionURL } from '../../store/slice/baseSlice';
import { selectImages, setImages } from '../../store/slice/imageSlice';
import { trackPromise } from 'react-promise-tracker';
import Skeleton from "@material-ui/lab/Skeleton";


function Resume() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL)
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);

  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      if (pages?.pages?.items?.[i].title === "Resume") {
        return i;
      }
    }
  };
  let pageID;

  const pageDataURL = (id) => {
    if (baseURL?.baseURL === prodURL) {
      return pages?.pages?.items?.[id].meta.detail_url.replace("http", "https");
    } else {
      return pages?.pages?.items?.[id].meta.detail_url.replace("https", "http");
    }
  };

  useEffect(() => {
    pageID = pageId();

    axios.get(pageDataURL(pageID)).then((res) => {
      dispatch(
        setPage({
          page: res.data,
        })
      );
    });

    if (images?.items?.length < 2) {
      axios.get(baseURL?.baseURL + "/api/v2/images/").then((res) => {
        dispatch(
          setImages({
            images: res.data,
          })
        );
      })
    }

  }, [pages?.pages]);

  return (
    <Container className="resume">
      <Header header="My Skills" />
      <Grid className="row">
        {page?.skills ?
          page?.skills?.map(function (skill) {
            return (
              <Grid item md={6} className="resume__skill">
                <Skill title={skill.value.title} percentage={skill.value.percentage} />
              </Grid>
            );
          })
        :
        <>
          <Grid item md={6} spave={2} className="resume__skill">
            <Skeleton height={50} width={75} />
            <Skeleton width={500} />
          </Grid>
          <Grid item md={6} spave={2} className="resume__skill">
            <Skeleton height={50} width={75} />
            <Skeleton width={500} />
          </Grid>
          <Grid item md={6} spave={2} className="resume__skill">
            <Skeleton height={50} width={75} />
            <Skeleton width={500} />
          </Grid>
          <Grid item md={6} spave={2} className="resume__skill">
            <Skeleton height={50} width={75} />
            <Skeleton width={500} />
          </Grid>
          <Grid item md={6} spave={2} className="resume__skill">
            <Skeleton height={50} width={75} />
            <Skeleton width={500} />
          </Grid>
          <Grid item md={6} spave={2} className="resume__skill">
            <Skeleton height={50} width={75} />
            <Skeleton width={500} />
          </Grid>
        </>
        }
      </Grid>

      <Header header="My Resume" />
      
      <TimelineWrapper occupation="work" data={page?.work_experience} />
      <TimelineWrapper occupation="education" data={page?.education_experience} />
    </Container>
  );
}

export default Resume;
