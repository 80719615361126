import React from "react";
import "./TimelineWrapper.css";
import Timeline from "./Timeline";
import Skeleton from "@material-ui/lab/Skeleton";
import { Container, Grid } from "@material-ui/core";

function TimelineWrapper({ occupation, data }) {
  const title = (occupation) => {
    if (occupation === "education") {
      return "Education";
    } else if (occupation === "work") {
      return "Work";
    }
  };

  return (
    <div className="timelinewrapper">
      <div className="timelinewrapper__title">
        <span></span>
        <h4>{title(occupation)} Experience</h4>
      </div>
      {data ?
        data?.map(function (experience) {
          return (
            <Timeline
              startdate={experience.value.start_date}
              enddate={experience.value.end_date}
              title={experience.value.title}
              subtitle={experience.value.subtitle}
              description={experience.value.body}
              stylename=""
            />
          );
        })
      :
        <Grid className='row'>
          <Grid item md={4} space={2}>
            <Skeleton height={150} width={300} />
          </Grid>
          <Grid item md={8} space={2}>
            <Skeleton height={50} width={75} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Grid>          
        </Grid>
      }    
    </div>
  );
}

export default TimelineWrapper;
