import React from "react";
import "./AboutMe.css";
import CustomButton from "../CustomButton";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectPage } from "../../store/slice/pageSlice";
import { selectBaseURL } from "../../store/slice/baseSlice";
import Skeleton from "@material-ui/lab/Skeleton";

function AboutMe() {
  const baseURL = useSelector(selectBaseURL);
  const page = useSelector(selectPage);

  return (
    <div className="aboutme">
      <div className="row">
        <Grid container>
          <Grid item md={6} className="aboutme__image">
            {page?.image_resized?.url ? (
              <img src={baseURL?.baseURL + page?.image_resized?.url} alt="" />
            ) : (
              <Skeleton
                animation="wave"
                height={750}
                width={500}
                className="aboutme__image__skeleton"
              />
            )}
          </Grid>
          <Grid item md={6} className="aboutme__info">
            <div className="aboutme__title">
              {page?.image_resized?.url ? (
                <h3>
                  I am <span className="color-theme">Dylan Primic</span>{" "}
                </h3>
              ) : (
                <Skeleton variant="h3" animation="wave" height={50} />
              )}
            </div>
            {page?.about_me ? (
              <div
                className="aboutme__message"
                dangerouslySetInnerHTML={{ __html: page?.about_me }}
              ></div>
            ) : (
              <>
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </>
            )}

            <div className="aboutme__info__text">
              <ul>
                {page?.full_name ? (
                  <li>
                    <b className="aboutme__info__text__bold">Full Name</b>
                    {page?.full_name}
                  </li>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
                {page?.age ? (
                  <li>
                    <b className="aboutme__info__text__bold">Age</b>
                    {page?.age}
                  </li>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
                {page?.nationality ? (
                  <li>
                    <b className="aboutme__info__text__bold">Nationality</b>
                    {page?.nationality}
                  </li>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
                {page?.languages ? (
                  <li>
                    <b className="aboutme__info__text__bold">Languages</b>
                    {page?.languages}
                  </li>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
                {page?.location ? (
                  <li>
                    <b className="aboutme__info__text__bold">Location</b>
                    {page?.location}
                  </li>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
                {page?.freelance ? (
                  <li>
                    <b className="aboutme__info__text__bold">Freelance</b>
                    {page?.freelance}
                  </li>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
              </ul>
              <div className="aboutme__downloadcv">
                {page?.cv_document ? (
                  <CustomButton
                    title="Download CV"
                    link={page?.cv_document?.meta.download_url}
                  />
                ) : (
                  <Skeleton variant="text" animation="wave" height={75} width={150} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AboutMe;
