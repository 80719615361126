import React from 'react';
import './Review.css';
import { Card, CardContent } from '@material-ui/core';

function Review({ review, name, client }) {
    return (
        <div className="review">
            <Card className="review__card">
                <CardContent className="review__cardcontent">
                    <p>{review}</p>
                </CardContent>
            </Card>
            <div className="review__author">
                <h5>{name}</h5>
                <h6>{client}</h6>
            </div>
        </div>
    )
}

export default Review
