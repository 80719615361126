import React, { useEffect } from "react";
import "./Contact.css";
import Header from "../Header";
import ContactContainer from "./ContactContainer";
import CustomButton from "../CustomButton";
import {
  Container,
  Grid,
} from "@material-ui/core";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from "react-redux";
import { setPage, selectPage, selectPages } from "../../store/slice/pageSlice";
import { selectBaseURL, selectProductionURL } from '../../store/slice/baseSlice';
import { selectImages, setImages } from '../../store/slice/imageSlice';
import { trackPromise } from 'react-promise-tracker';
import { Skeleton } from "@material-ui/lab";

function Contact() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL)
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [contactForm, setContactForm] = React.useState(true);
  const recaptchaRef = React.createRef();
  const [isVerified, setIsVerified] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState("")
  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      if (pages?.pages?.items?.[i].title === "Contact Me") {
        return i;
      }
    }
  };
  let pageID;

  const pageDataURL = (id) => {
    if (baseURL?.baseURL === prodURL) {
      return pages?.pages?.items?.[id].meta.detail_url.replace("http", "https");
    } else {
      return pages?.pages?.items?.[id].meta.detail_url.replace("https", "http");
    }
  };

  const submitContactForm = (e) => {
    if (isVerified) {
      e.preventDefault();

      // Post Form Data to the API
      const contact_details = {
        name: name,
        email: email,
        subject: subject,
        message: message,
        recaptcha: recaptchaToken
      };

      axios
        .post(baseURL?.baseURL + "/api/contact-me/", contact_details)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      // Set the form values back to empty
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setContactForm(false);
    } else {
      e.preventDefault();
      alert("Please verify that your are a human!");
    }
  };

  const recaptchaComplete = (value) => {
    setIsVerified(true);
    setRecaptchaToken(value)
  }

  const recaptchaExpired = () => {
    setIsVerified(false);
    setRecaptchaToken("");
  }

  useEffect(() => {
    pageID = pageId();

    axios.get(pageDataURL(pageID)).then((res) => {
      dispatch(
        setPage({
          page: res.data,
        })
      );
    });

    if (images?.items?.length < 2) {
      axios.get(baseURL?.baseURL + "/api/v2/images/").then((res) => {
        dispatch(
          setImages({
            images: res.data,
          })
        );
      })
    }

  }, [pages?.pages]);

  return (
    <Container className="contact">
      <Header header="Contact Me" />

      <Grid className="contact__wrapper">
        <Grid item md={6}>
          {contactForm ? (
            <h4>Get In Touch</h4>
          ) : (
            <h4>
              Thank you for contacting me!{" "}
              <span>I will get back to you within 48 hours.</span>
            </h4>
          )}
          {contactForm && (
            <form className="contact__form" onSubmit={submitContactForm}>
              <div className="formfield">
                <label for="name-input" className="formfield__label">
                  Enter Your Name*
                </label>
                <input
                  id="name-input"
                  name="name"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required={true}
                />
              </div>
              <div className="formfield">
                <label for="email-input" className="formfield__label">
                  Enter Your Email*
                </label>
                <input
                  id="email-input"
                  type="text"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required={true}
                />
              </div>
              <div className="formfield">
                <label for="subject-input" className="formfield__label">
                  Enter Your Subject*
                </label>
                <input
                  id="subject-input"
                  type="text"
                  name="subject"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  required={true}
                />
              </div>
              <div className="formfield">
                <label for="message-input" className="formfield__label">
                  Enter Your Message*
                </label>
                <textarea
                  id="message-input"
                  type="textarea"
                  cols="30"
                  rows="6"
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  required={true}
                />
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LfwDdIZAAAAAF3AB_Ip7kmaAMWCDIlwQ-Q9yTiY"
                onChange={recaptchaComplete}
                onExpired={recaptchaExpired}
                theme="dark"
                // size="invisible"
              />
              <CustomButton title="Contact Me" type="submit" />
            </form>
          )}
        </Grid>
        <Grid item md={6}>
          <div className="contact__details">
            {page?.contact_info ?
              page?.contact_info?.map(function (contact) {
                if (contact.type === "phone") {
                  return (
                    <ContactContainer
                      icon={<PhoneOutlinedIcon />}
                      title="Phone"
                      detail={contact.value}
                    />
                  );
                } else if (contact.type === "email") {
                  return (
                    <ContactContainer
                      icon={<DraftsOutlinedIcon />}
                      title="Email"
                      detail={contact.value?.[0].value}
                      link={contact.value?.[0].value}
                    />
                  );
                }
              })
            :
              <>
                <Skeleton variant='text' height={200} width={500} />
                <Skeleton variant='text' height={200} width={500} />
              </>
            }
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;
