import React, { useEffect } from 'react';
import './SideBar.css';
import MenuItem from './MenuItem';
import { Typography } from '@material-ui/core';
import { useDataLayerValue } from "../DataLayer";
import { useSelector, useDispatch } from "react-redux";
import { setPages, setPage, selectPage, selectPages } from "../../store/slice/pageSlice";
import { selectBaseURL, selectProductionURL } from '../../store/slice/baseSlice';
import { selectImages, selectProfileImage } from '../../store/slice/imageSlice';
import Skeleton from '@material-ui/lab/Skeleton';

function SideBar() {
    const baseURL = useSelector(selectBaseURL);
    const images = useSelector(selectImages);
    const profile_picture = useSelector(selectProfileImage)

    const [profile_picture_url, setProfile_Picture_URL] = React.useState("")

    useEffect(() => {
        if (images?.items?.length > 2 && profile_picture) {
            setProfile_Picture_URL(baseURL?.baseURL + images?.items?.[profile_picture[0].profile_picture - 1]?.meta.download_url);
        }
    }, [images])


    return (
        <div className="sidebar">
            
                <div className="sidebar__profile">
                    {profile_picture_url ?
                        profile_picture_url && <img src={profile_picture_url} 
                        alt="Dylan Primic" 
                        className="sidebar__profilepicture"
                        />
                    :
                        <Skeleton variant="circle" animation='wave' width={200} height={200} />
                    }
                </div>

                <div className="siderbar__menu">
                    {profile_picture_url ?
                        <>
                            <MenuItem name="Home" page="/"/>
                            <MenuItem name="About Me" page="/about-me"/>
                            <MenuItem name="Resume" page="/resume"/>
                            <MenuItem name="Portfolio" page="/portfolio"/>
                            <MenuItem name="Blog" page="/blog"/>
                            <MenuItem name="Contact Me" page="/contact"/>
                        </>
                    :
                        <>
                            <Skeleton variant='text' animation='wave' height={40} />
                            <Skeleton variant='text' animation='wave' height={40} />
                            <Skeleton variant='text' animation='wave' height={40} />
                            <Skeleton variant='text' animation='wave' height={40} />
                            <Skeleton variant='text' animation='wave' height={40} />
                            <Skeleton variant='text' animation='wave' height={40} />
                        </>
                        
                    }
                </div>

                {/* Footer */}
                <div className="siderbar__footer">
                    <Typography>© 2021 Copyright</Typography>
                </div>
        </div>
    )
}

export default SideBar
