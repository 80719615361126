import React, { useState, useEffect } from "react";
import "./BlogPostDetail.css";
import Header from "../Header";
import BlogPost from "./BlogPost";
import { Container, Grid, Paper, Card } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import {
  setPage,
  selectPage,
  selectPages,
  setBlogPosts,
  selectBlogPosts,
} from "../../store/slice/pageSlice";
import {
  selectBaseURL,
  selectProductionURL,
} from "../../store/slice/baseSlice";
import { selectImages, setImages } from "../../store/slice/imageSlice";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import Post from "./Post/Post";
import Body from "./Post/Body";
import ReactPrismjs from "@uiw/react-prismjs";

function BlogPostDetail() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL);
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);

  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      let converted_title = pages?.pages?.items?.[i].title
        .split(" ")
        .join("_")
        .toLowerCase();
      let post_slug = window.location.href.split("post-").pop();
      if (converted_title === post_slug) {
        return pages?.pages?.items?.[i].id;
      }
    }
  };
  let pageID;

  const blogDetailURL = (id) => {
    let blogURL = baseURL?.baseURL + "/api/v2/pages/" + id;
    if (baseURL?.baseURL === prodURL) {
      return blogURL.replace("http", "https");
    } else {
      return blogURL.replace("https", "http");
    }
  };

  const publishedDate = () => {
    let new_date = page?.meta.first_published_at.slice(0, 10);
    return new_date;
  };

  useEffect(() => {
    pageID = pageId();

    trackPromise(
      axios.get(blogDetailURL(pageID)).then((res) => {
        dispatch(
          setPage({
            page: res.data,
          })
        );
      })
    );
  }, [pages?.pages]);

  return (
    <div className="blogpostdetail">
      <Container>
        <div className="post__headerimage">
          <Card elevation={3}>
            <Container>
              <img src={baseURL?.baseURL + page?.image_resized?.url} alt="" />
            </Container>
          </Card>
        </div>

        <div className="post__header">
          <Header header={page?.title} />
        </div>

        <div className="post__body">
          {page?.body ? (
            page?.body?.map(function (info) {
              if (info.type == "text") {
                return (
                  <div
                    className="post__bodytext"
                    dangerouslySetInnerHTML={{ __html: info.value }}
                  ></div>
                );
              } else if (info.type == "code") {
                return (
                  <div className="post__bodycode">
                    <Paper elevation={3}>
                      <ReactPrismjs
                        className="code__block"
                        language={info.value?.language}
                        source={info.value?.code}
                      />
                    </Paper>
                  </div>
                );
              }
            })
          ) : (
            <div>The blog post is empty</div>
          )}
        </div>

        <div className="post__footer">{/* <p>Bunch of #'s</p> */}</div>
      </Container>
    </div>
  );
}

export default BlogPostDetail;
