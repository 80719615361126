import React from "react";
import "./BlogPost.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PostDate from "./PostDate";
import { Container } from "@material-ui/core";

function BlogPost({ date, title, image, description, link }) {
  return (
    <div className="blogpost">
      <Link to={link}>
        <Container>
          <div className="blogpost__image">
            <PostDate date={date} />

            <img src={image} alt="" />
          </div>
          <div className="blogpost__content">
            <h5>
              {" "}
              <Link to={link}>{title}</Link>
            </h5>
          </div>
        </Container>
      </Link>
    </div>
  );
}

export default BlogPost;
