import React, { useEffect } from "react";
import "./Home.css";
import axios from "axios";
import Particles from "react-particles-js";
import { Typography, IconButton } from "@material-ui/core";
import { GitHub, Facebook, Instagram, Twitter } from "@material-ui/icons";
import Skeleton from '@material-ui/lab/Skeleton';
import Slide from "./Slide";
import MenuIcon from "@material-ui/icons/Menu";
import { useDataLayerValue } from "../DataLayer";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { setPages, setPage, selectPage, selectPages } from "../../store/slice/pageSlice";
import { selectBaseURL, selectProductionURL } from '../../store/slice/baseSlice';
import { selectImages, setImages } from '../../store/slice/imageSlice';
import { trackPromise } from 'react-promise-tracker';


function Home() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL)
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);


  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      if (pages?.pages?.items?.[i].title === "Home") {
        return i;
      }
    }
  };
  let pageID;

  const pageDataURL = (id) => {
    if (baseURL?.baseURL === prodURL) {
      return pages?.pages?.items?.[id].meta.detail_url.replace("http", "https");
    } else {
      return pages?.pages?.items?.[id].meta.detail_url.replace("https", "http");
    }
  };

  useEffect(() => {
    pageID = pageId();

    axios.get(pageDataURL(pageID)).then((res) => {
      dispatch(
        setPage({
          page: res.data,
        })
      );
    });

    if (images?.items?.length < 2) {
      axios.get(baseURL?.baseURL + "/api/v2/images/").then((res) => {
        dispatch(
          setImages({
            images: res.data,
          })
        );
      });
    }

  }, [pages?.pages]);

  return (
    <div className="home">
      <Particles
        params={{
          particles: {
            number: {
              value: 160,
              density: {
                enable: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                speed: 4,
                size_min: 0.3,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              random: true,
              speed: 1,
              direction: "top",
              out_mode: "out",
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "bubble",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
            },
            modes: {
              bubble: {
                distance: 250,
                duration: 2,
                size: 0,
                opacity: 0,
              },
              repulse: {
                distance: 400,
                duration: 4,
              },
            },
          },
        }}
        width="97%"
      />

      <div className="home__body">
        <h1 className="home__bodytitle">
          {page?.body_text ?
            <>
            Hello There, My name is <span>Dylan Primic</span>
            </>
          :
            <Skeleton variant='rect' animation='wave' />}
        </h1>
        {page?.body_text ?
          <div
            className="home__bodymessage"
            dangerouslySetInnerHTML={{ __html: page?.body_text }}
          ></div>
        :
          <div
            className="home__bodymessage"
          >
            <Skeleton variant="text" animation="wave" />   
            <Skeleton variant="text" animation="wave" />   
            <Skeleton variant="text" animation="wave" />   
            <Skeleton variant="text" animation="wave" />            
          </div>
        }

        <div className="home__bodysocial">
          {page?.social ?
                    page?.social?.map(function (socialbutton) {
                      if (socialbutton.type == "twitter") {
                        return (
                          <Tooltip title="Twitter Profile">
                            <IconButton
                              className="home_bodysocial__button"
                              href={socialbutton.value}
                              target="_blank"
                            >
                              <Twitter />
                            </IconButton>
                          </Tooltip>
                        );
                      } else if (socialbutton.type == "facebook") {
                        return (
                          <Tooltip title="Facebook Profile">
                            <IconButton
                              className="home_bodysocial__button"
                              href={socialbutton.value}
                              target="_blank"
                            >
                              <Facebook />
                            </IconButton>
                          </Tooltip>
                        );
                      } else if (socialbutton.type == "instagram") {
                        return (
                          <Tooltip title="Instagram Profile">
                            <IconButton
                              className="home_bodysocial__button"
                              href={socialbutton.value}
                              target="_blank"
                            >
                              <Instagram />
                            </IconButton>
                          </Tooltip>
                        );
                      } else if (socialbutton.type == "github") {
                        return (
                          <Tooltip title="Github Profile">
                            <IconButton
                              className="home_bodysocial__button"
                              href={socialbutton.value}
                              target="_blank"
                            >
                              <GitHub />
                            </IconButton>
                          </Tooltip>
                        );
                      }
                    })
          :
            <Skeleton variant='circle' animation='wave' />
          }

        </div>

        {/* <Slide /> */}
      </div>
    </div>
  );
}

export default Home;
