import React from 'react';
import './Skill.css';
import { LinearProgress, Container } from '@material-ui/core';

function Skill({ title, percentage }) {

    return (
        <Container className="skill">
            <div className="skill__title">
                <h6>{title}</h6>
            </div>
            <div className="skill__inner">
                <div className="skill__inner__percentage">
                    {percentage}%
                </div>
                <div className="skill__inner__container">
                    <LinearProgress className="skill__progress" variant="determinate" value={percentage} />
                </div>
            </div>
        </Container>
    )
}

export default Skill
