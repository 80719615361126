import React from 'react';
import './Service.css';
import { Card, CardContent } from '@material-ui/core';

function Service({ icon, title, body }) {
    return (
        <div className="service">
                    <Card className="service__card">
                        <CardContent className="service__cardcontent">
                            <span className="service__cardcontent__icon">
                                {icon}
                            </span>
                            <h5>{title}</h5>
                            <p>{body}</p>
                        </CardContent>
                    </Card>
        </div>
    )
}

export default Service
