import React from 'react';
import './Project.css';


function Project({ image, title, description, isOpen, setisOpen, button, trybutton, link, fullimage, github }) {

    return (
        <div className="project">
            <div className="project__image">
                <img src={image} alt="" />
                <div className="project__image__overlay">
                    {button}
                    {trybutton}
                    {github}
                </div>
            </div>
            <div className="project__details">
                <h5>{title}</h5>
                <h6>{description}</h6>
            </div>
        </div>
    )
}

export default Project
