import React, { useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SideBar from "./Components/SideBar/SideBar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Resume from "./Components/Resume/Resume";
import Portfolio from "./Components/Portfolio/Portfolio";
import Blog from "./Components/Blog/Blog";
import Contact from "./Components/Contact/Contact";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import MenuIcon from "@material-ui/icons/Menu";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { setPages, selectPage } from "./store/slice/pageSlice";
import { selectBaseURL, setBaseURL } from "./store/slice/baseSlice";
import { setImages, setProfileImage } from "./store/slice/imageSlice";
import CookieConsent from "react-cookie-consent";
import LoadingIndicator from "./Components/LoadingIndicator";
import BlogPostDetail from "./Components/Blog/BlogPostDetail";
import { trackPromise } from "react-promise-tracker";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  //#region Consts declarations
  const [checked, setChecked] = React.useState(false);

  // Responsive Variables
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const [width, height] = useWindowSize();

  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const page = useSelector(selectPage);

  // End of Responsive Variables

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleBaseURL = () => {
    let url = "";
    if (window.location.hostname === "localhost") {
      dispatch(
        setBaseURL({
          baseURL: "http://localhost:8000",
        })
      );
      url = "http://localhost:8000";
    } else if (window.location.hostname === "192.168.1.200") {
      dispatch(
        setBaseURL({
          baseURL: "http://192.168.1.200:8000",
        })
      );
      url = "http://192.168.1.200:8000";
    } else if (window.location.hostname === "portfolio.localserver") {
      dispatch(
        setBaseURL({
          baseURL: "http://portfolio.localserver",
        })
      );
      url = "http://portfolio.localserver";
    } else {
      dispatch(
        setBaseURL({
          baseURL: "https://dylan.primic.com",
        })
      );
      url = "https://dylan.primic.com";
    }
    return url;
  };

  //#endregion

  // UseEffect where we get all the API data
  useEffect(() => {
    const apiURL = handleBaseURL();

    trackPromise(
      axios
        .all([
          axios.get(apiURL + "/api/v2/pages/"),
          axios.get(apiURL + "/api/v2/images/"),
          axios.get(apiURL + "/api/profile/"),
        ])
        .then(
          axios.spread((pages, images, profile) => {
            // console.log('Pages >', pages.data, 'Images >', images.data, 'Profile >', profile.data);
            dispatch(
              setPages({
                pages: pages.data,
              })
            );

            dispatch(
              setImages({
                images: images.data,
              })
            );

            dispatch(
              setProfileImage({
                profile_pic: profile.data,
              })
            );
          })
        )
    );
  }, [baseURL?.baseURL]);

  useEffect(() => {
    if (width >= 1365) {
      setChecked(true);
    }
    else {
      setChecked(false);
    }
  }, [page, width]);

  return (
    <Router>
      <div className="app">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dylan's Portfolio</title>
          <meta name="description" content="Helmet application" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
        <div className="app__sidebar">
          {/* Navbar */}
          <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
            <Paper elevation={4} className="app__sidebar__paper">
              <SideBar />
            </Paper>
          </Slide>
        </div>

        <div className="app__page">
          <div className="app__sidebarbutton">
            {checked ? (
              <CloseOutlinedIcon onClick={handleChange} fontSize="large" />
            ) : (
              <MenuIcon onClick={handleChange} fontSize="large" />
            )}
          </div>

          <LoadingIndicator />

          <Switch>
            <Route path="/about-me">
              <About />
            </Route>
            <Route path="/resume">
              <Resume />
            </Route>
            <Route path="/portfolio">
              <Portfolio />
            </Route>
            <Route exact path="/blog">
              <Blog />
            </Route>
            <Route path="/blog/post-*">
              <BlogPostDetail />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            {/* This is the default path */}
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>

          <div className="cookie__agreement">
            <CookieConsent
              buttonText="I Agree"
              cookieName="CookieConsent"
              containerClasses="cookie__agreement__container"
              buttonClasses="cookie__agreement__button"
              disableStyles={true}
              expires={150}
            >
              <p>This website uses cookies to enhance the user experience.</p>
            </CookieConsent>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
