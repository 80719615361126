import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Reviews.css";
import Review from "./Review";
import { useSelector } from "react-redux";
import { selectPage } from "../../store/slice/pageSlice";
import Skeleton from "@material-ui/lab/Skeleton";

function Reviews() {
  const page = useSelector(selectPage);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="reviews">
      {page?.reviews ?
        <Slider {...settings}>
        {page?.reviews?.map(function (review) {
          return (
            <div>
              <Review
                review={review.value.review}
                name={review.value.category}
                client={review.value.name}
              />
            </div>
          );
        })}
      </Slider>
        :
        <div className='reviews__skeleton__loader'>
          <Skeleton animation='wave' height={500} width={500} />
        </div>
      }
      
    </div>
  );
}

export default Reviews;
