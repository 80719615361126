import React from "react";
import "./CustomButton.css";

function CustomButton({ title, tooltip, type, link }) {
  return (
    <div className="button__container">
      {link ? 
      <a href={link} download>
        <button className="button" variant="contained" type={type}>
          {title}
        </button>
      </a>
      :
      <button className="button" variant="contained" type={type}>
        {title}
      </button>
    }
    </div>
  );
}

export default CustomButton;
