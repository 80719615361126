import React, { useEffect } from "react";
import "./About.css";
import axios from "axios";
import { Container, Grid } from "@material-ui/core";
import { Code } from "@material-ui/icons";
import Header from "../Header";
import AboutMe from "./AboutMe";
import Service from "./Service";
import Reviews from "./Reviews";
import { useSelector, useDispatch } from "react-redux";
import { setPage, selectPage, selectPages } from "../../store/slice/pageSlice";
import { selectBaseURL, selectProductionURL } from '../../store/slice/baseSlice';
import { selectImages, setImages } from '../../store/slice/imageSlice';
import { trackPromise } from 'react-promise-tracker';
import Skeleton from "@material-ui/lab/Skeleton";


function About() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL)
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);

  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      if (pages?.pages?.items?.[i].title === "About Me") {
        return i;
      }
    }
  };
  let pageID;

  const pageDataURL = (id) => {
    if (baseURL?.baseURL === prodURL) {
      return pages?.pages?.items?.[id].meta.detail_url.replace("http", "https");
    } else {
      return pages?.pages?.items?.[id].meta.detail_url.replace("https", "http");
    }
  };

  useEffect(() => {
    pageID = pageId();

    trackPromise(axios.get(pageDataURL(pageID)).then((res) => {
      dispatch(
        setPage({
          page: res.data,
        })
      );
    }));

    if (images?.items?.length < 2) {
      trackPromise(axios.get(baseURL?.baseURL + "/api/v2/images/").then((res) => {
        dispatch(
          setImages({
            images: res.data,
          })
        );
      }))
    }

  }, [pages?.pages]);

  return (
    <Container className="about">

      {/* About Me  */}
      <Header header="About Me" />
      <AboutMe />

      {/* Services */}
      <Header header="Services" />

      <div className="row">
        <Grid className="about__services">
          {page?.services ?
            page?.services?.map(function (serv) {
              return (
                <Grid item xs={6} space={2}>
                  <Service
                    icon={<Code className="service__icon" />}
                    title={serv.value.title}
                    body={serv.value.body}
                  />
                </Grid>
              );
            })
          :
            <>
              <Grid item xs={6} space={2}>
                <Skeleton animation='wave' height={250} width={500} />
              </Grid>
              <Grid item xs={6} space={2}>
                <Skeleton animation='wave' height={250} width={500} />
              </Grid>
              <Grid item xs={6} space={2}>
                <Skeleton animation='wave' height={250} width={500} />
              </Grid>
              <Grid item xs={6} space={2}>
                <Skeleton animation='wave' height={250} width={500} />
              </Grid>            
            </>
          }
        </Grid>
      </div>

      {/* Reviews */}
      <Header header="Reviews" />
      <Reviews />
    </Container>
  );
}

export default About;
