import { createSlice } from '@reduxjs/toolkit';

export const pageSlice = createSlice({
    name: 'page',
    initialState: {
        pages: null,
        page: null,
        blog: null,
    },
    reducers: {
        setPages: (state, action) => {
            state.pages = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setBlogPosts: (state, action) => {
            state.blog = action.payload;
        },
    }
});

export const { setPages, setPage, setBlogPosts } = pageSlice.actions;

export const selectPages = (state) => state.pages?.pages;
export const selectPage = (state) => state.pages?.page?.page;
export const selectBlogPosts = (state) => state.pages?.blog;

export default pageSlice.reducer;