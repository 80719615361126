import React from "react";
import "./Timeline.css";
import { Grid } from "@material-ui/core";

function Timeline({
  startdate,
  enddate,
  title,
  subtitle,
  description,
  stylename,
}) {
  const classname = (stylename) => {
    if (stylename) {
      return "timeline__summary " + stylename;
    } else {
      return "timeline__summary";
    }
  };

  return (
    <Grid className="timeline">
      <Grid item md={4} className={classname(stylename)}>
        <h6>
          {parseInt(startdate)} - {parseInt(enddate)}
        </h6>
      </Grid>
      <Grid item md={8} className="timeline__details">
        <h5>{title}</h5>
        <h6>{subtitle}</h6>
        <p>{description}</p>
      </Grid>
    </Grid>
  );
}

export default Timeline;
