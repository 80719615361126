import React from 'react';
import './ContactContainer.css';
import { Container } from '@material-ui/core';

function ContactContainer({ icon, title, detail, link }) {

    const mailtolink = (link) => {
        if (link?.length > 1) {
            return "mailto://" + link
        }
        else {
            return ""
        }
    }

    return (
        <Container className="contactcontainer">
            <div className="contactcontainer__icon">
                {icon}
            </div>
            <div className="contactcontainer__detail">
                <h5>{title}</h5>
                <a href={mailtolink(link)}>{detail}</a>
            </div>
        </Container>
    )
}

export default ContactContainer
