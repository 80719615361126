import React, { useState, useEffect } from "react";
import "./Portfolio.css";
import Header from "../Header";
import Project from "./Project";
import { Container, Grid } from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import PublicIcon from "@material-ui/icons/Public";
import LinkIcon from '@material-ui/icons/Link';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { setPage, selectPage, selectPages } from "../../store/slice/pageSlice";
import { selectBaseURL, selectProductionURL } from '../../store/slice/baseSlice';
import { selectImages, setImages } from '../../store/slice/imageSlice';
import { trackPromise } from 'react-promise-tracker';


function Portfolio() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL)
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);

  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      if (pages?.pages?.items?.[i].title === "Portfolio") {
        return i;
      }
    }
  };
  let pageID;

  const pageDataURL = (id) => {
    if (baseURL?.baseURL === prodURL) {
      return pages?.pages?.items?.[id].meta.detail_url.replace("http", "https");
    } else {
      return pages?.pages?.items?.[id].meta.detail_url.replace("https", "http");
    }
  };

  useEffect(() => {
    setisOpen(false);
    pageID = pageId();

    trackPromise(axios.get(pageDataURL(pageID)).then((res) => {
      dispatch(
        setPage({
          page: res.data,
        })
      );
    }));

    if (images?.items?.length < 2) {
      trackPromise(axios.get(baseURL?.baseURL + "/api/v2/images/").then((res) => {
        dispatch(
          setImages({
            images: res.data,
          })
        );
      }))
    }

  }, [pages?.pages]);

  const [pageIndex, setPageIndex] = React.useState(1);
  const handleChange = (event, value) => {
    setPageIndex(value);
  };

  const [isOpen, setisOpen] = useState(1);
  const openLightbox = (imageindex) => {
    setphotoIndex(imageindex);
    setisOpen(true);
  };
  const closeLightbox = () => {
    setisOpen(false);
  };

  const arraystart = () => {
    if (pageIndex <= 1) {
      return 0;
    } else {
      const sum = 9 * pageIndex - 9;
      return sum;
    }
  };

  const arrayend = () => {
    if (pageIndex <= 1) {
      return 9;
    } else {
      const sum = 9 * pageIndex;
      return sum;
    }
  };

  const arraypagecount = () => {
    const sum = Math.ceil(page?.projects?.length / 9);
    return sum;
  };

  const [photoIndex, setphotoIndex] = useState(1);
  let currentimage = baseURL?.baseURL + images?.items?.[photoIndex]?.meta.download_url;
  let nextimage = baseURL?.baseURL + images?.items?.[photoIndex + 1]?.meta.download_url;
  const nextimagerequest = () => {
    // Set Max limix
    if (nextimage === baseURL?.baseURL + "undefined") {
      setphotoIndex(photoIndex);
    } else {
      if (images?.items?.[photoIndex + 1]?.meta.tags.includes("project")) {
        setphotoIndex(photoIndex + 1);
      } else {
        let hasTag = false;
        let pictureId = photoIndex + 1;
        while (hasTag === false && pictureId <= images?.items?.length) {
          pictureId = pictureId + 1;
          if (images?.items?.[pictureId]?.meta.tags.includes("project")) {
            hasTag = true;
          }
        }
        if (hasTag === true) {
          setphotoIndex(pictureId);
        } else {
          setphotoIndex(photoIndex);
        }
      }
    }
  };
  let previousimage =
    baseURL?.baseURL + images?.items?.[photoIndex - 1]?.meta.download_url;
  const previousimagerequest = (index) => {
    // Set Min Limit
    if (previousimage === baseURL?.baseURL + "undefined") {
      setphotoIndex(photoIndex);
    } else {
      if (images?.items?.[photoIndex - 1]?.meta.tags.includes("project")) {
        setphotoIndex(photoIndex - 1);
      } else {
        let hasTag = false;
        let pictureId = photoIndex - 1;
        while (hasTag === false && pictureId >= 0) {
          pictureId = pictureId - 1;
          if (images?.items?.[pictureId]?.meta.tags.includes("project")) {
            hasTag = true;
          }
        }
        if (hasTag === true) {
          setphotoIndex(pictureId);
        } else {
          setphotoIndex(photoIndex);
        }
      }
    }
  };

  return (
    <Container className="portfolio">
      <Header header="Portfolio" />
      <Grid className="row portfolio__projectwrapper">
        {page?.projects ?
          page?.projects?.slice(arraystart(), arrayend()).map(function (project) {
            return (
              <Grid item xl={4} lg={6} md={6} sm={12}>
                <Project
                  image={
                    baseURL?.baseURL +
                    images?.items?.[project.value.image - 1]?.meta.download_url
                  }
                  title={project.value.title}
                  description={project.value.info}
                  isOpen={isOpen}
                  button={
                    <Tooltip title="Zoom In">
                      <IconButton
                        onClick={() => openLightbox(project.value.image - 1)}
                      >
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  trybutton={
                    project.value.tryurl ? (
                      <a href={project.value.tryurl} target="_blank">
                        <Tooltip title="Try Out">
                          <IconButton>
                            <PublicIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                    ) : null
                  }
                  github={
                    project.value.url ? (
                      <a href={project.value.url} target="_blank">
                        <Tooltip title="GitHub">
                            <IconButton>
                                <LinkIcon />
                            </IconButton>
                        </Tooltip>
                      </a>
                    ) : null
                  }
                  link={project.value.url}
                  fullimage={
                    baseURL?.baseURL +
                    images?.items?.[project.value.image - 1]?.meta.download_url
                  }
                />
              </Grid>
            );
          })
        :
          <Grid className='row'>
            <Grid item xl={4} lg={6} md={6} sm={12}>
              <Skeleton variant="rect" height={300} width={500} />
              <Skeleton height={50} width={500} />
              <Skeleton height={40} width={500} />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12}>
              <Skeleton variant="rect" height={300} width={500} />
              <Skeleton height={50} width={500} />
              <Skeleton height={40} width={500} />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12}>
              <Skeleton variant="rect" height={300} width={500} />
              <Skeleton height={50} width={500} />
              <Skeleton height={40} width={500} />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12}>
              <Skeleton variant="rect" height={300} width={500} />
              <Skeleton height={50} width={500} />
              <Skeleton height={40} width={500} />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12}>
              <Skeleton variant="rect" height={300} width={500} />
              <Skeleton height={50} width={500} />
              <Skeleton height={40} width={500} />
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={12}>
              <Skeleton variant="rect" height={300} width={500} />
              <Skeleton height={50} width={500} />
              <Skeleton height={40} width={500} />
            </Grid>
          </Grid>
        }
      </Grid>

      <div className="portfolio__pagination">
        <Pagination
          className="portfolio__pagination"
          count={arraypagecount()}
          page={pageIndex}
          onChange={handleChange}
          variant="rounded"
        />
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={currentimage}
          nextSrc={nextimage}
          prevSrc={() => previousimage(photoIndex)}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={nextimagerequest}
          onMoveNextRequest={previousimagerequest}
        />
      )}
    </Container>
  );
}

export default Portfolio;
