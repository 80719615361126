import { createSlice } from '@reduxjs/toolkit';

export const baseSlice = createSlice({
    name: 'base',
    initialState: {
        prodURL: "https://dylan.primic.com",
        baseURL: null,
        isLoading: true,
    },
    reducers: {
        setBaseURL: (state, action) => {
            state.baseURL = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
});

export const { setBaseURL, setIsLoading } = baseSlice.actions;

export const selectBaseURL = (state) => state.base.baseURL;
export const selectProductionURL = (state) => state.base.prodURL;
export const selectIsLoading = (state) => state.base.isLoading;

export default baseSlice.reducer;