import React from 'react';
import './Post.css';
import Body from './Body';


function Post({ image }) {
    return (
        <div className="post">

            <div className="post__header">
                <h1>Post Title!</h1>
                <h5>04 November 2020</h5>
            </div>

            <div className="post__headerimage">
                <img src={image} alt=""/>
            </div>

            <Body data="Body data will be sent here" />

            <div className="post__footer">
                <p>Bunch of #'s</p>
            </div>
        </div>
    )
}

export default Post
