import React, { useState, useEffect } from "react";
import "./Blog.css";
import Header from "../Header";
import BlogPost from "./BlogPost";
import { Container, Grid } from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import {
  setPage,
  selectPage,
  selectPages,
  setBlogPosts,
  selectBlogPosts,
} from "../../store/slice/pageSlice";
import {
  selectBaseURL,
  selectProductionURL,
} from "../../store/slice/baseSlice";
import { selectImages, setImages } from "../../store/slice/imageSlice";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

function Blog() {
  const dispatch = useDispatch();
  const baseURL = useSelector(selectBaseURL);
  const prodURL = useSelector(selectProductionURL);
  const pages = useSelector(selectPages);
  const page = useSelector(selectPage);
  const images = useSelector(selectImages);
  const blogposts = useSelector(selectBlogPosts);

  const pageId = () => {
    let i;
    for (i = 0; i < pages?.pages?.items?.length; i++) {
      if (pages?.pages?.items?.[i].title === "Blog") {
        return i;
      }
    }
  };
  let pageID;

  const pageDataURL = (id) => {
    if (baseURL?.baseURL === prodURL) {
      return pages?.pages?.items?.[id].meta.detail_url.replace("http", "https");
    } else {
      return pages?.pages?.items?.[id].meta.detail_url.replace("https", "http");
    }
  };

  const blogListURL = (id) => {
    let blogURL = baseURL?.baseURL + "/api/blog/";
    if (baseURL?.baseURL === prodURL) {
      return blogURL.replace("http", "https");
    } else {
      return blogURL.replace("https", "http");
    }
  };

  useEffect(() => {
    pageID = pageId();

    trackPromise(
      axios.get(pageDataURL(pageID)).then((res) => {
        dispatch(
          setPage({
            page: res.data,
          })
        );
      })
    );

    trackPromise(
      axios.get(blogListURL(pageID)).then((res) => {
        dispatch(setBlogPosts(res.data));
      })
    );

    // if (images?.items?.length < 2) {
    //   trackPromise(
    //     axios.get(baseURL?.baseURL + "/api/v2/images/").then((res) => {
    //       dispatch(
    //         setImages({
    //           images: res.data,
    //         })
    //       );
    //     })
    //   );
    // }
  }, [pages?.pages]);

  const [blogpage, setBlogPage] = React.useState(1);
  const handleChange = (event, value) => {
    setBlogPage(value);
  };

  const arraystart = () => {
    if (blogpage <= 1) {
      return 0;
    } else {
      const sum = 6 * blogpage - 6;
      return sum;
    }
  };

  const arrayend = () => {
    if (blogpage <= 1) {
      return 6;
    } else {
      const sum = 6 * blogpage;
      return sum;
    }
  };

  const arraypagecount = () => {
    const sum = Math.ceil(blogposts?.length / 6);
    return sum;
  };

  const postURL = (title) => {
    return "";
  };

  const sorted_blogposts = () => {
    let sorted = blogposts?.sort(
      (a, b) => b.date.split("-").join("") - a.date.split("-").join("")
    );
    console.log("Sorted");
    return sorted;
  };

  return (
    <Container className="blog">
      <Header header="Recent Posts" />
      <Grid className="blog__postwrapper">
        {blogposts ? (
          blogposts?.[0]?.title ? (
            blogposts
              ?.slice(arraystart(), arrayend())
              ?.sort(function (a, b) {
                console.log(a.date - b.date);
                return b.date.split("-").join("") - a.date.split("-").join("");
              })
              ?.map(function (post, index) {
                return (
                  <Grid item md={6} className="blog__post">
                    <Container>
                      <BlogPost
                        title={post.title}
                        image={
                          baseURL?.baseURL +
                          images?.items?.[post.image - 1]?.meta.download_url
                        }
                        date={post.date}
                        link={
                          "/blog/post-" +
                          post.title.split(" ").join("_").toLowerCase()
                        }
                      />
                    </Container>
                  </Grid>
                );
              })
          ) : (
            <p>There are no blog posts yet!</p>
          )
        ) : (
          <>
            <Grid item md={6} className="blog__post">
              <Skeleton variant="rect" width={500} height={300} />
              <Skeleton width={500} />
            </Grid>
            <Grid item md={6} className="blog__post">
              <Skeleton variant="rect" width={500} height={300} />
              <Skeleton width={500} />
            </Grid>
          </>
        )}
      </Grid>

      <div className="blog__pagination">
        <Pagination
          className="portfolio__pagination"
          count={arraypagecount()}
          page={blogpage}
          onChange={handleChange}
          variant="rounded"
        />
      </div>
    </Container>
  );
}

export default Blog;
