import React from 'react'
import './MenuItem.css';
import { NavLink } from 'react-router-dom'

function MenuItem({name, page, className}) {

    return (

    <NavLink to={page} exact className="menuitem">{name}</NavLink>
    )
}

export default MenuItem
