import React, { useEffect } from 'react';
import './LoadingIndicator.css';
import { usePromiseTracker } from "react-promise-tracker";
import CircularProgress from '@material-ui/core/CircularProgress'
import { selectIsLoading, setIsLoading } from '../store/slice/baseSlice';
import { useSelector, useDispatch } from "react-redux";


function LoadingIndicator() {
    const { promiseInProgress } = usePromiseTracker();
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (promiseInProgress === true) {
    //         dispatch(setIsLoading(true));
    //     }
    //     else {
    //         setTimeout(() => {
    //             if (promiseInProgress === false) {
    //                 dispatch(setIsLoading(false));
    //             }
    //         }, 1200)
    //     }
    // }, [isLoading, promiseInProgress])

    return (<div></div>
        // isLoading === true && 
            // <div className={isLoading === true ? 'loadingindicator show' : 'loadingindicator'}>
                // <CircularProgress />
            // </div>
    )
}

export default LoadingIndicator
